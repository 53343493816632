import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.areaName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.areaName) = $event)),
      readonly: "",
      clickable: "",
      clearable: "",
      "is-link": "",
      label: "区域类型",
      placeholder: "请选择区域类型",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = true))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.show) = $event)),
      style: { height: '93%' },
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              class: "areatype-popup",
              modelValue: _ctx.area,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.area) = $event)),
              label: "区域类型",
              placeholder: "请选择区域类型",
              clearable: ""
            }, {
              button: _withCtx(() => [
                _createElementVNode("span", {
                  style: {"color":"#1989fa"},
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
                }, "取消")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_van_pull_refresh, {
          modelValue: _ctx.refreshing,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.refreshing) = $event)),
          onRefresh: _ctx.onRefresh
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_list, {
              class: "van-list",
              loading: _ctx.loading,
              "onUpdate:loading": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.loading) = $event)),
              finished: _ctx.finished,
              "finished-text": "没有更多了",
              onLoad: _ctx.onLoad
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areaList, (item) => {
                  return (_openBlock(), _createBlock(_component_van_cell, {
                    key: item.id,
                    title: item.name,
                    onClick: ($event: any) => (_ctx.selectAreaEvent(item))
                  }, null, 8, ["title", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["loading", "finished", "onLoad"])
          ]),
          _: 1
        }, 8, ["modelValue", "onRefresh"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}