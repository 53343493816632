
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/qualitycheck.ts";

@Options({})
export default class extends Vue {
  private areaName = "";
  private area = "";
  private show = false;
  private finished = false;
  private refreshing = false;
  private loading = false;
  private areaList: any[] = [];

  @Watch("area")
  onAreaChanged(newValue: string, oldValue: string) {
    if (newValue === "") {
      this.areaName = "";
      this.$emit("selectArea", "");
    }
    this.onRefresh();
  }

  protected cancel() {
    this.show = false;
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.areaList = [];
        this.refreshing = false;
      }
      api
        .projectAreaType({
          projectId: this.$store.state.project.projectinfo.projectId,
          name: this.area,
        })
        .then((res: any) => {
          this.areaList = res.data;
          this.loading = false;
          this.finished = true;
          this.refreshing = false;
        });
    }, 1000);
  }

  protected selectAreaEvent(item: any) {
    this.areaName = item.name;
    this.area = item.name;
    this.show = false;
    this.$emit("selectArea", item.id);
  }

  // 参建单位上拉刷新
  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
