
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/safecheck";

@Options({})
export default class extends Vue {
  private show = false;
  private personName = "";
  private refreshing = false;
  private checkList: any[] = [];
  private loading = false;
  private finished = false;
  private fieldValue = "";

  @Watch("personName")
  onPersonNameChanged(newValue: string, oldValue: string) {
    if (newValue === "") {
      this.fieldValue = "";
      this.$emit("selectUserManger", "");
    }
    this.checkList = [];
    this.onRefresh();
  }

  protected showPopup() {
    this.show = true;
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.checkList = [];
        this.refreshing = false;
      }
      api
        .pageListUser({
          pageNum: parseInt(this.checkList.length / 20 + "") + 1,
          pageSize: 20,
          projectId: this.$store.state.project.projectinfo.projectId,
          name: this.personName,
        })
        .then((res) => {
          this.checkList = [...this.checkList, ...res.data.records];
          this.loading = false;
          if (this.checkList.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  protected cancel() {
    this.show = false;
  }

  protected selectUserEvent(item: any) {
    this.personName = item.name;
    this.fieldValue = item.name;
    this.show = false;
    this.$emit("selectUserManger", item.userId);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
